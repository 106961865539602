import React, { useState, useEffect } from 'react'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Button, Spinner, Modal, ModalBody, ButtonGroup } from 'reactstrap'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'
import TopNavigation from '../TopNavigation'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'
import { Collapse, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useWindowDimensions from '../useWindowsDimensions'
import { useCookies } from 'react-cookie'
import Fab from '@material-ui/core/Fab'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MuiBadge from '@material-ui/core/Badge'
import { getStatus } from '../components/ActivityUtil'
import OwnActivity from '../components/OwnActivity'
import QRCode from 'react-qr-code'


const mapStateToProps = (state, props) => {
  const {
    firestore,
    firebase: { profile },
    globaltime: { gtime },
  } = state
  const { appconfig } = props
  if (firestore.data['ownActivities']) {
    let accesscode = profile.accesscode ? profile.accesscode[appconfig.accountkey] : null
    if (accesscode && appconfig.accesscodeextra) accesscode += appconfig.accesscodeextra

    let _m = Object.keys(firestore.data['ownActivities'])
      .map((key) => {
        return {
          ...firestore.data['ownActivities'][key],
          id: key,
          selectkey: key,
        }
      })
      .filter((r) => r.locationid === appconfig.accountkey)
      .map((r) => {
        const ordering = moment.utc(r.end)
        if (moment().utcOffset() > 0) {
          ordering.subtract(moment().utcOffset(), 'minute')
        } else {
          ordering.add(moment().utcOffset(), 'minute')
        }
        r.ordering = ordering.unix()
        r.occurency = { date: moment.utc(r.start).format('YYYY-MM-DD') }
        return r
      })
      .flat(3)
      .filter((r) => {
        if (appconfig.nextseasonhide) {
          if (moment(r.occurency.date).isAfter(appconfig.nextseasonhide)) {
            return false
          } else {
            return true
          }
        }
        return true
      })
      .map((r) => {
        r.vs = getStatus(r, gtime)
        return r
      })

    _m.sort(function (a, b) {
      if (a.ordering < b.ordering) {
        return -1
      } else if (a.ordering > b.ordering) {
        return 1
      } else {
        return 0
      }
    })

    let _upcoming = _m.filter((x) => x.vs.isUpcoming && !x.vs.isCancelled)
    let _past = _m.filter((x) => x.vs.isPast && !x.vs.isCancelled)
    let _cancelled = _m.filter((x) => x.vs.isCancelled)
    const _unpaid = _m.filter((x) => {
      if (x.vs.saleState && x.vs.saleState.p === 1) return false
      if (!x.vs.isPaid) {
        if (x.vs.isStored) {
          return true
        }
        if (x.vs.isCancelled) {
          return false
        }
        return true
      }
      return false
    })

    return {
      activities: _upcoming,
      pastactivities: _past,
      cancelled: _cancelled,
      unpaid: _unpaid,
      owns: firestore.data['ownActivities'],
      accesscode,
      gtime,
    }
  } else {
    return { activities: undefined, gtime }
  }
}

const withActivities = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [{ collection: 'activities' }],
      where: [
        ['userstate', '==', 'participating']
      ],
      storeAs: 'ownActivities',
    },
    // {
    //   collection: 'users',
    //   doc: `${props.auth.uid}`,
    //   subcollections: [
    //     { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
    //   ],
    //   storeAs: 'wallets',
    // },
  ]),
  connect(mapStateToProps)
)
/*
const checkCourtAndTimeChange = (reservation, parent) => {
  const courtchanged = reservation.resourcegroups[0].resources[0].id !== parent.resourcegroups[0].resources[0].id
  // check time
  const _parentstart = moment.utc(parent.resourcegroups[0].start)
  const _parentend = moment.utc(parent.resourcegroups[0].end)
  const _start = moment.utc(reservation.resourcegroups[0].start)
  const _end = moment.utc(reservation.resourcegroups[0].end)
  const timechanged = !(
    _parentstart.hour() === _start.hour() &&
    _parentstart.minute() === _start.minute() &&
    _parentend.hour() === _end.hour() &&
    _parentend.minute() === _end.minute()
  )
  return { courtchanged, timechanged }
}
*/
const OwnActivitiesScreen = ({ auth, dispatch, activities, pastactivities, cancelled, unpaid, accesscode, appconfig, owns, gtime }) => {
  const firebase = useFirebase()
  let location = useLocation()
  let defaultLanguage = 'FI'
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
//  const [servertime, setServertime] = useState(null)
//  const [canceltime, setCanceltime] = useState(null)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [showReservations, setShowReservations] = useState('upcoming')
  const [expanded, setExpanded] = useState({})
  const [selectedActivities, setSelectedActivities] = useState([])
  const [cancellableActivity, setCancellableActivity] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [walletsDisabled, setWalletsDisabled] = useState(false)
  const [signinOpen, setSigninOpen] = useState(false)
  const [signinCode, setSigninCode] = useState(null)
  const history = useHistory()
  const { width } = useWindowDimensions()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }

  useEffect(() => {
    moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
  }, [defaultLanguage])

  const updateTimes = () => {
    const degugTime = new URLSearchParams(location.search).get('time')
    fetch('/index.html').then((response) => {
      const time = degugTime ? moment(degugTime) : moment(new Date(response.headers.get('Date')))
      const offset = time.utcOffset()
      time.add(offset, 'minutes')
//      setServertime(time)
//      let t2 = moment(time).add(appconfig.cancellimit || 24, 'hours')
 //     setCanceltime(t2)
    })
  }

  useEffect(() => {
    updateTimes()
    const timer = setInterval(() => updateTimes(), 120000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   // console.log('update disable')
  //   const wallets = selectedReservations.map((x) => {
  //     return getWallet(appconfig, { category: x.resourcegroups[0].resources[0].category })
  //   })
  //   const isAllSameWallet = wallets.every((val, i, arr) => val === arr[0])
  //   // console.log('wallets', wallets)
  //   // console.log('isAllSameWallet', isAllSameWallet)
  //   // console.log('selectedReservations', selectedReservations)
  //   setWalletsDisabled(!isAllSameWallet)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedReservations])

  // const goToPayReservation = (reservation, d) => {
  //   dispatch({
  //     type: 'UPDATE_PAYABLE_RESERVATION',
  //     payload: reservation,
  //   })
  //   history.push(appconfig.pathPrefix + '/payonly')
  // }

  const goToPayActivities = () => {
    dispatch({
      type: 'UPDATE_PAYABLE_ACTIVITY',
      payload: selectedActivities,
    })
    history.push(appconfig.pathPrefix + '/payonly')
  }

  const openSignin = (code) => {
    const _code = code.split('')
    const _uid = auth.uid.split('')
    if (_code.length > _uid.length) {
      _uid.push(...Array(_code.length - _uid.length).fill('.'))
    } else if (_uid.length > _code.length) {
      _code.push(...Array(_uid.length - _code.length).fill('.'))
    }
    const full = _code.map((x, i) => x + _uid[i]).join('')
    console.log(_code, _uid, full)
    setSigninCode(full)
    setSigninOpen(true)
  }

  const cancelModal = () => {
    setCancellableActivity(null)
    setConfirmationOpen(false)
  }

  const confirmCancel = (activity) => {
    setCancellableActivity(activity)
    setConfirmationOpen(true)
  }

  const cancelActivity = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    const activityObject = {
      id: cancellableActivity.id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testactivities-cancel' : 'activities-cancel'
    let cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(activityObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }


  const handleRowChange = (id, change) => {
    if (change === 'remove') {
      unselectRow(id)
    } else {
      selectRow(id)
    }
  }


  const selectRow = (id) => {
    const activity = activities.find((x) => x.id === id)
    activity.collection = 'activities'
    setSelectedActivities((x) => [...x, activity])
  }

  const unselectRow = (id) => {
    // console.log('unselect', id, occurencydate)
    setSelectedActivities((x) =>
      x.filter((x) => {
        // console.log(x)
        return !(x.id === id)
      })
    )
  }

  const handleExpandClick = (timeIndex) => {
    let v = true
    if (expanded[timeIndex]) v = false
    setExpanded({ ...expanded, [timeIndex]: v })
  }

  const getCurrentReservations = (agg, ai, i) => {
    if (i > 0) {
      return (
        <React.Fragment key={ai + '-' + i + '-u'}>
          <Row
            className='align-baseline'
            style={{ marginRight: 0, marginLeft: 0, marginTop: 5, paddingBottom: 5, alignItems: 'baseline' }}
            // key={ai}
          >
            <Col xs={2} md={1}>
              <IconButton onClick={() => handleExpandClick(ai)}>
                {expanded[ai] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Col>
            <Col>
              <h6 style={{ cursor: 'pointer', textTransform: 'lowercase' }} onClick={() => handleExpandClick(ai)}>
                {moment.utc(ai, 'YYYYMM').format('MMMM YYYY')}, {agg[ai].length}{' '}
                {agg[ai].length < 2 ? t('reservation') : t('reservations')}
              </h6>
            </Col>
          </Row>
          <Collapse in={expanded[ai]} timeout='auto' unmountOnExit>
            {agg[ai]}
          </Collapse>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment key={ai + '-' + i + '-ue'}>
          <Row
            className=''
            style={{ marginRight: 0, marginLeft: 0, marginTop: 5, paddingBottom: 5 }}
            // key={ai}
          >
            <Col xs={2} md={1}></Col>
            <Col>
              <h6>
                {moment.utc(ai, 'YYYYMM').format('MMMM YYYY')}, {agg[ai].length}{' '}
                {agg[ai].length < 2 ? t('reservation') : t('reservations')}
              </h6>
            </Col>
          </Row>
          {agg[ai]}
        </React.Fragment>
      )
    }
  }

  const getFilteredReservations = (agg, ai, i) => {
    return (
      <React.Fragment key={ai + '-' + i + '-p'}>
        <Row
          className='align-baseline'
          style={{ marginRight: 0, marginLeft: 0, marginTop: 5, paddingBottom: 5, alignItems: 'baseline' }}
          // key={ai}
        >
          <Col xs={2} md={1}>
            <IconButton onClick={() => handleExpandClick(ai)}>
              {expanded[ai] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Col>
          <Col>
            <h6 style={{ cursor: 'pointer', textTransform: 'lowercase' }} onClick={() => handleExpandClick(ai)}>
              {moment.utc(ai, 'YYYYMM').format('MMMM YYYY')}, {agg[ai].length}{' '}
              {agg[ai].length < 2 ? t('reservation') : t('reservations')}
            </h6>
          </Col>
        </Row>
        <Collapse in={expanded[ai]} timeout='auto' unmountOnExit>
          {agg[ai]}
        </Collapse>
      </React.Fragment>
    )
  }


  const downloadReceipt = (id) => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        let downloadUrl = 'https://europe-west1-falcon-328a1.cloudfunctions.net/receipt-downloadActivity?cid=' + appconfig.accountkey + '&id=' + id + '&token=' + idTokenResult.token
        window.open(downloadUrl, '_blank')
      })
  }

  const aggregateActivities = (activities, current, isUnpaidView) => {
    const agg = activities.reduce((a, r, i) => {
      // if (!r.occurency) console.log(r)
      let index = moment.utc(r.start).format('YYYYMM')
      if (!a[index]) a[index] = []
      r.description = { fi: '', sv: '', en: '' }
      r.type = 'Ac'
      a[index].push(<OwnActivity r={r} gtime={gtime} onSignin={openSignin} onChange={handleRowChange} onCancel={confirmCancel} onDownloadReceipt={downloadReceipt}/>)
      return a
    }, {})
    return Object.keys(agg)
      .sort()
      .map((ai, i) => {
        if (current) {
          return getCurrentReservations(agg, ai, i)
        } else {
          return getFilteredReservations(agg, ai, i)
        }
      })
  }

  let loaded = isLoaded(activities) && isLoaded(pastactivities)
  let emptyActivities = isEmpty(activities)
  // let emptyPast = isEmpty(pastactivities)
  let ownReservations =
    loaded || emptyActivities ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  let ownPastReservations =
    loaded || emptyActivities ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  let ownCancelledReservations =
    loaded || emptyActivities ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  let ownUnpaidReservations =
    loaded || emptyActivities ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  if (loaded) {
    if (activities.length > 0) {
      ownReservations = aggregateActivities(activities, true)
    }
    if (pastactivities.length > 0) {
      ownPastReservations = aggregateActivities(pastactivities, false)
    }
    if (cancelled.length > 0) {
      ownCancelledReservations = aggregateActivities(cancelled, false)
    }
    if (unpaid.length > 0) {
      ownUnpaidReservations = aggregateActivities(unpaid, false, true)
    }
  }
  console.log('selectedActivities', selectedActivities)
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front'>
        <Row>
          <Col className='text-center tesoma-page-title'>
            <Typography variant='h5'>{t('own_activities')}</Typography>
          </Col>
        </Row>
        <Row>
          <Col className='text-center mb-3'>
            <ButtonGroup style={{ flexWrap: 'wrap' }} size='sm'>
              <Button
                color='primary'
                active={showReservations === 'upcoming'}
                outline
                onClick={() => setShowReservations('upcoming')}
              >
                {t('show_upcoming')}
              </Button>
              <Button
                color='primary'
                active={showReservations === 'past'}
                outline
                onClick={() => setShowReservations('past')}
              >
                {t('show_history')}
              </Button>
              {/* <Button
                color='primary'
                active={showReservations === 'cancelled'}
                outline
                onClick={() => setShowReservations('cancelled')}
              >
                {t('show_cancelled')}
              </Button> */}
              <Button
                color='primary'
                active={showReservations === 'unpaid'}
                outline
                onClick={() => setShowReservations('unpaid')}
              >
                {t('show_unpaid')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md='12' className='mx-auto'>
            {loaded || emptyActivities ? (
              //  <List component='nav' aria-labelledby='nested-list-subheader'>
              <>
                {showReservations === 'past'
                  ? ownPastReservations
                  : showReservations === 'cancelled'
                  ? ownCancelledReservations
                  : showReservations === 'unpaid'
                  ? ownUnpaidReservations
                  : ownReservations}
              </>
            ) : (
              // </List>
              <div>Loading ...</div>
            )}
          </Col>
        </Row>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={walletsDisabled}
          // onClose={handleClose}
          message='Saldo/Lompakko maksutapa on käytössä vain jos kaikki valitut varaukset kohdistuvat samaan saldoon.'
          key={'verticalhorizontal'}
          // style={{ bottom: 90 }}
        />
        {selectedActivities.length > 0 ? (
          <Fab
            variant='extended'
            size='large'
            // color="orange"
            aria-label='selected'
            style={{
              margin: 0,
              top: 'auto',
              right: 20,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
              backgroundColor: '#f9a825',
            }}
            onClick={goToPayActivities}
          >
            <MuiBadge
              style={{ marginRight: 10 }}
              badgeContent={selectedActivities.length}
              color='error'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ShoppingCartIcon />
            </MuiBadge>
            {' kassalle '} {selectedActivities.reduce((a, c) => a + c.vs.missingPayment.amount, 0) + ' €'}
          </Fab>
        ) : null}
      </Container>
      <Modal
        size='sm'
        centered
        isOpen={loading}
        backdrop={true}
        wrapClassName={'al-wrap'}
        modalClassName={'al-modal'}
        contentClassName={'loading-spinner'}
      >
        <ModalBody className='text-center'>
          <Spinner color='light' /> <p style={{ color: 'white' }}>{t('cancelling')}</p>
        </ModalBody>
      </Modal>
      <Dialog
        open={confirmationOpen}
        onClose={() => cancelModal()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Haluatko varmasti perua osallistumisen?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelModal()} color='secondary'>
            {t('back')}
          </Button>
          <Button onClick={() => cancelActivity()} color='danger' autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={signinOpen}
        fullScreen={width < 576}
        onClose={() => setSigninOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <QRCode value={signinCode} style={{ height: "auto", maxWidth: "100%", width: "100%" }} title='Johan on' />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSigninOpen(false)} color='secondary'>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withAppconfig(withActivities(OwnActivitiesScreen))
