import React, { useEffect, useState } from 'react'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import { connect } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import TopNavigation from '../TopNavigation'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../useWindowsDimensions'
import withAppconfig from '../AppContext'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const enhance = connect(({ firebase: { auth, profile } }) => ({
  auth,
  profile,
}))

function ImpLogScreen({ auth, profile, dispatch, appconfig }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [screen, setScreen] = useState('login')
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [tcmodal, setTCModal] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState(null)
  const [impersonating, setImpersonating] = useState()
  const tcToggle = () => setTCModal(!tcmodal)
  const errorToggle = () => setErrorModalOpen(!errorModalOpen)
  let history = useHistory()
  let isLoggedIn = !isEmpty(auth)
  const { width } = useWindowDimensions()


  const customerAppTitle = width > 1000 ? appconfig.apptitle.long : appconfig.apptitle.short
  const mailTemplate = appconfig.templateset ? appconfig.templateset.email : 'default'

  const onForgetPress = async () => {
    // console.log('Pressed reset', this.state)
    if (email) {
      firebase.functions().region = 'europe-west1'
      let resetpassword = firebase.functions().httpsCallable('users-resetPassword')
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await resetpassword({ email, template: mailTemplate })
      } catch (error) {
        // console.log(error)
      }
    }
  }

  const onSignInPress = async () => {
    // console.log('Pressed login', this.state)
    if (email && password && impersonating) {
      try {
        firebase.functions().region = 'europe-west1'
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((reset) => {
            // console.log(' nyt vois mennä juureen ', reset)
            return firebase.functions().httpsCallable('superadmin-impersonating')({ impersonating })
          }).then((response) => {
            const token = response.data.token
            return firebase.auth().signInWithCustomToken(token)
          }).then((response) => {
            // console.log(' nyt vois mennä juureen ', response)
            history.push(appconfig.pathPrefix + '/')
          })
      } catch (error) {
        setLoginErrorMessage(error.message)
        setErrorModalOpen(true)
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn && screen === 'login') setScreen('logout')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoggedIn) {
    return (
      <div className='full-page-background'>
        {/* <TopNavigation /> */}
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
                    <span className='brandtext'>CINTOIA</span>
                    <h2>{t('logout')}</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <p className='text-center'>
                    {t('u_are_logged_in')} {profile.email}
                  </p>
                </FormGroup>
                <Button
                  color='primary'
                  block
                  onClick={() => {
                    firebase.logout().then((reset) => {
                      // dispatch({
                      //   type: 'USER_LOGOUT',
                      //   payload: null,
                      // })
                      history.push(appconfig.pathPrefix + '/')
                    })
                  }}
                >
                  {t('logout')}
                </Button>

                <Row>
                  <Col>
                    {' '}
                    <Button size='small' color='link' className='text-muted' onClick={() => history.push(appconfig.pathPrefix + '/')}>
                      <ChevronLeftIcon /> {t('back')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else if (screen === 'login') {
    return (
      <div className='full-page-background'>
        {/* <TopNavigation /> */}
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
                    <span className='brandtext'>CINTOIA</span>
                    <h2>{t('kirjaudu')}</h2>
                    <span className='appname'>{customerAppTitle}</span>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <Label for='email'>{t('email')}</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    placeholder={t('email')}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='password'>{t('password')}</Label>
                  <Input
                    type='password'
                    name='password'
                    id='password'
                    placeholder={t('password')}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='impersonating'>{t('impersonating')}</Label>
                  <Input
                    type='email'
                    name='impersonating'
                    id='impersonating'
                    placeholder={t('impersonating')}
                    onChange={(event) => setImpersonating(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <p className='text-center'>
                    {' '}
                    <Button color='link' onClick={tcToggle}>
                      {t('accepttandc')}
                    </Button>
                  </p>
                </FormGroup>
                <Button
                  disabled={!(password && email)}
                  color='primary'
                  className='loose-form'
                  block
                  onClick={() => {
                    onSignInPress()
                  }}
                  // onClick={() =>
                  //   firebase
                  //     .auth()
                  //     .signInWithEmailAndPassword(email, password)
                  //     .then((reset) => {
                  //       // console.log(' nyt vois mennä juureen ', reset)

                  //       history.push(appconfig.pathPrefix + '/')
                  //     })
                  //     .catch((error) => {
                  //       if (error.code === 'auth/user-disabled') {
                  //         setLoginErrorMessage('Tunnus disabloitu ylläpidon toimesta. Ota yhteyttä asiakaspalveluun.')
                  //       } else if (error.code === 'auth/wrong-password') {
                  //         setLoginErrorMessage('Väärä salasana')
                  //       } else if (error.code === 'auth/too-many-requests') {
                  //         setLoginErrorMessage(
                  //           'Liian paljon sisäänkirjautumisyrityksiä. Tunnus poistettu käytöstä turvallisuussyistä. Voit ottaa tunnuksen käyttöön nollaamalla salasanan tai kokeilemalla hetken päästä uudelleen.'
                  //         )
                  //       } else if (error.code === 'auth/invalid-email') {
                  //         setLoginErrorMessage('Vääränmuotoinen sähköpostiosoite')
                  //       } else if (error.code === 'auth/user-not-found') {
                  //         setLoginErrorMessage('Ei löydy käyttäjää tunnuksella ' + email)
                  //       } else {
                  //         setLoginErrorMessage('Tuntematon virhe sisäänkirjauksessa.')
                  //       }
                  //       errorToggle()
                  //       // console.log(error)
                  //     })
                  // }
                >
                  {t('kirjaudu')}
                </Button>
                <Button color='link' className='loose-form' onClick={() => history.push(appconfig.pathPrefix + '/register')}>
                  {t('registerhere')}
                </Button>
                <Button color='link' className='loose-form' onClick={() => setScreen('forgetpassword')}>
                  {t('forgetpassword')}
                </Button>
                <Row>
                  <Col>
                    {' '}
                    <Button size='small' color='link' className='text-muted' onClick={() => history.push(appconfig.pathPrefix + '/')}>
                      <ChevronLeftIcon /> {t('back_to_reservation')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={tcmodal} toggle={tcToggle}>
          <ModalHeader toggle={tcToggle}>{t('tandc.reservation_terms')}</ModalHeader>
          <ModalBody>
            <div>
              <strong>{t('tandc.title1')}</strong>
              <p>{t('tandc.body1')}</p>
              <strong>{t('tandc.title2')}</strong>
              <p>{t('tandc.body2')}</p>
              <strong>{t('tandc.title3')}</strong>
              <p>{t('tandc.body3')}</p>
              <p>{t('tandc.body4').replaceAll('{CANCELLIMIT}', appconfig.cancellimit || '24')}</p>{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={tcToggle}>
              {t('close')}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorModalOpen} toggle={errorToggle}>
          <ModalHeader toggle={errorToggle}>{t('error_at_login')}</ModalHeader>
          <ModalBody>
            <div>{loginErrorMessage}</div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={errorToggle}>
              {t('close')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  } else if (screen === 'forgetpassword') {
    return (
      <div className='full-page-background'>
        {/* <TopNavigation /> */}
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
                    <span className='brandtext'>CINTOIA</span>
                    <h2>{t('password_reset')}</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <Label for='email'>{t('email')}</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Sähköpostiosoite'
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormGroup>
                <Button
                  disabled={!(email && email.length > 5)}
                  color='primary'
                  block
                  onClick={() => onForgetPress().then((reset) => setScreen('resetInfo'))}
                >
                  {t('send_password_reset')}
                </Button>
                <Button
                  style={{ marginTop: 30 }}
                  color='link'
                  className='loose-form'
                  onClick={() => setScreen('login')}
                >
                  {t('to_login')}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else if (screen === 'logout') {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
                    <span className='brandtext'>CINTOIA</span>
                    <h2>{t('logout')}</h2>
                  </div>
                </div>
                <Button
                  color='primary'
                  block
                  onClick={() => {
                    firebase.logout().then((reset) => {
                      dispatch({
                        type: 'USER_LOGOUT',
                        payload: null,
                      })
                      setScreen('login')
                    })
                  }}
                >
                  Kirjaudu ulos
                </Button>
                <Button
                  style={{ marginTop: 30 }}
                  color='link'
                  className='loose-form'
                  onClick={() => setScreen('login')}
                >
                  Kirjautumiseen
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else if (screen === 'resetInfo') {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <h2>Salasanan resetointi</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <p className='text-center'>
                    {email} osoitteeseen lähetetty linkki josta voi resetoida salasanan. Kun olet tehnyt resetoinnin
                    niin pääset kirjautumaan alla olevasta linkistä.
                  </p>
                </FormGroup>
                <Button
                  style={{ marginTop: 30 }}
                  color='link'
                  className='loose-form'
                  onClick={() => setScreen('login')}
                >
                  Kirjaudu
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <h2>Oops !</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <p className='text-center'>Jouduit jonnekkin outoon paikkaan!</p>
                </FormGroup>
                <Button color='link' className='loose-form' onClick={() => setScreen('login')}>
                  Takaisin kirjautumiseen
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withAppconfig(enhance(ImpLogScreen))
