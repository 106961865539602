import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../AppContext'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Button,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/fi'
import { Divider, TextField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
// import Payments from '../components/Payments'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import TopNavigation from '../TopNavigation'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Collapse from '@material-ui/core/Collapse'
import { TransitionGroup } from 'react-transition-group'
import { withStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';


const BlueSwitch = withStyles({
  switchBase: {
    // color: blue[300],
    '&$checked': {
      color: blue[500],
    },
    '&$checked + $track': {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

const enhance = compose(
  connect(({ splittable: { selected } }) => {
    if (selected) {
      // console.log('selected', selected)
      const summary = { price: 0 }
      if (
        selected.resourcegroups &&
        selected.resourcegroups.length > 0 &&
        selected.resourcegroups[0].resources &&
        selected.resourcegroups[0].resources.length > 0
      ) {
        summary.displayName = selected.resourcegroups[0].resources[0].displayName
        summary.start = selected.resourcegroups[0].start
        summary.end = selected.resourcegroups[0].end
        summary.category = selected.resourcegroups[0].resources[0].category
        let _sum = 0
        if (selected.payments) {
          _sum = selected.payments.reduce((a, x) => a + x.amount, 0) / 100
          if (selected.occurency) {
            _sum =
              selected.payments
                .filter((x) => selected.occurency && x.occurency === selected.occurency.date)
                .reduce((a, x) => a + x.amount, 0) / 100
          }
        }
        const cost = selected.resourcegroups[0].overridecost
          ? selected.resourcegroups[0].overridecost
          : selected.resourcegroups[0].cost || 0
        summary.price = cost - _sum
        if (selected.occurency) {
          const _s1 = moment.utc(selected.resourcegroups[0].start)
          const _s2 = moment.utc(selected.occurency.date)
          summary.start = moment
            .utc()
            .year(_s2.year())
            .month(_s2.month())
            .date(_s2.date())
            .hour(_s1.hour())
            .minute(_s1.minute())
            .second(0)
            .format()
        }
      }
      return { selected, summary }
    } else {
      return { selected, summary: null }
    }
  })
)

function SplitterScreen({ selected, summary, dispatch, appconfig }) {
  const firebase = useFirebase()
  firebase.functions().region = 'europe-west1'
  let history = useHistory()
  // const params = useParams()
  // let defaultLanguage = 'FI'
  const { t } = useTranslation()
  const [splits, setSplits] = useState([])
  const [sumerror, setSumerror] = useState(0)

  const [loading, setLoading] = useState(true)
  const [paymysplit, setPaymysplit] = useState(true)
  // const [paymysplitvalue, setPaymysplitvalue] = useState(false)

  const isSummaryLoaded = summary !== null

  useEffect(() => {
    if (summary) {
      setSplits([])
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSplitsPricing = (sp) => {
    const shareprice = parseFloat((summary.price / sp.length).toFixed(2))
    const s = sp.map((sx, i) => {
      if (i === 0 && paymysplit) {
        if (sx.di) {
          return { ...sx, title: t('your_share'), price: shareprice - sumerror, d: true }
        } else {
          return { ...sx, title: t('your_share'), price: shareprice, d: true }
        }
      } else {
        if (sx.d) {
          delete sx.d
          sx.title = ''
        }
        if (sx.di) {
          return { ...sx, price: shareprice - sumerror }
        } else {
          return { ...sx, price: shareprice }
        }
      }
    })
    const _splitsum = s.reduce((a, c) => a + c.price, 0)
    if (_splitsum !== summary.price && s.length > 0) {
      setSumerror(parseFloat((_splitsum - summary.price).toFixed(2)))
    } else {
      setSumerror(0)
    }
    return s
  }

  const removeSplit = (id) => {
    // console.log('remove', id)
    const s = splits
      .filter((sx) => sx.id !== id)
      .map((sx, i) => {
        return { ...sx, id: i + 1 }
      })
    if (s.length > 1) {
      setSplits(updateSplitsPricing(s))
    } else if (s.length === 1 && paymysplit) {
      setSplits([])
    } else {
      setSplits([])
    }
  }

  const addSplit = () => {
    const s = splits.map((sx) => sx)
    s.push({ price: 0, id: s.length + 1 })
    if (s.length === 1 && paymysplit) {
      s.push({ price: 0, id: s.length + 1 })
    }
    setSplits(updateSplitsPricing(s))
  }

  const didMount = React.useRef(false)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    }
    setSplits(updateSplitsPricing(splits))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymysplit])

  const changeMySplit = (event) => {
    let val = event.target.checked
    // setPaymysplitvalue(val)
    setPaymysplit(val)
  }

  const changeTitle = (id, text) => {
    const s = splits.map((sx) => {
      if (sx.id === id) {
        return { ...sx, title: text }
      } else {
        return sx
      }
    })
    setSplits(s)
  }

  const setDiff = (id) => {
    const s = splits.map((sx) => {
      if (sx.id === id) {
        sx.di = true
      } else {
        delete sx.di
      }
      return sx
    })
    setSplits(updateSplitsPricing(s))
    
  }

  const goReservations = () => {
    dispatch({
      type: 'CLEAR_SPLITTABLE_RESERVATION',
      payload: null,
    })
    history.push(appconfig.pathPrefix + '/reservations')
  }

  if (loading) {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row>
            <Col className='text-center'>
              <Spinner />
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else if (!isSummaryLoaded) {
    goReservations()
  } else {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>{t('split_payment')}</CardHeader>
                <CardBody>
                  <CardTitle tag='h5'>{summary.displayName}</CardTitle>
                  <Row className={'loose'}>
                    <Col>{summary.title}</Col>
                  </Row>
                  <Row className={'loose'}>
                    <Col>{summary.message}</Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('day')}</Col>
                    <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
                      {moment(summary.start).utc().format('dddd D.M.YYYY')}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('time')}</Col>
                    <Col className={'text-right'}>
                      {moment(summary.start).utc().format('H:mm')} - {moment(summary.end).utc().format('H:mm')}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('price')}</Col>
                    <Col className={'text-right'}>{summary.price} €</Col>
                  </Row>
                  {splits.length > 1 ? (
                    <Row>
                      <Col xs={3} md={2}></Col>
                      <Col xs={8} md={9}>
                        {t('split_title')}
                      </Col>
                      <Col xs={2} className='text-right'></Col>
                    </Row>
                  ) : null}
                  <TransitionGroup>
                    {splits.map((s, i) => {
                      if (s.price) {
                        return (
                          <Collapse key={s.id}>
                            <Row key={s.id}>
                              <Col xs={3} md={2}>
                                {s.price} €
                                {sumerror ? <ArrowLeftIcon onClick={() => setDiff(s.id)} style={s.di ? { color: '#000000' } : { color: '#bdbdbd' }} />: null}
                              </Col>
                              <Col xs={8} md={9}>
                                <TextField
                                  disabled={s.d}
                                  value={s.title || ''}
                                  fullWidth
                                  onChange={(event) => changeTitle(s.id, event.target.value)}
                                ></TextField>
                              </Col>
                              <Col xs={1} className='text-right align-text-top'>
                                {!s.d ? <RemoveCircleOutlineIcon onClick={() => removeSplit(s.id)} /> : null}
                              </Col>
                            </Row>
                          </Collapse>
                        )
                      }
                      return null
                    })}
                  </TransitionGroup>
                  {sumerror ? (
                    <Row>
                      <Col>{t('split_not_even') + ' ' +  (sumerror > 0 ? t('split_over') : t('split_under')) + ' ' + sumerror} €</Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Button size='small' color='link' onClick={() => addSplit()}>
                      <AddCircleOutlineIcon />
                    </Button>
                  </Row>
                  <Row className={'loose'}>
                    <Col className={'text-right'}>
                      <FormControlLabel
                        control={
                          <BlueSwitch
                            checked={paymysplit}
                            onChange={changeMySplit}
                            name='NotPayMySplit'
                            color='primary'
                          />
                        }
                        label={paymysplit ? t('paying_my_split') : t('not_paying_my_split')}
                        labelPlacement='start'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-right'>
                      <Button
                        disabled={splits.find((x) => !x.title) !== undefined}
                        color={'primary'}
                        size='small' /*onClick={() => goReservations()}*/
                      >
                        {t('next')}
                      </Button>
                    </Col>
                  </Row>
                  <CardText></CardText>
                  <Button size='small' color='link' className='text-muted' onClick={() => goReservations()}>
                    <ChevronLeftIcon /> {t('back_to_reservation')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>{JSON.stringify(summary)}</div>
          <div>{JSON.stringify(splits)}</div>
          <div>{JSON.stringify(paymysplit)}</div>
          <div>{JSON.stringify(splits.find((x) => !x.title))}</div>
        </Container>
        <Modal
          size='sm'
          centered
          isOpen={loading}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' /> <p style={{ color: 'white' }}>Vahvistetaan</p>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default withAppconfig(enhance(SplitterScreen))
