import React, { useState } from 'react'
import { isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import TopNavigation from '../TopNavigation'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../useWindowsDimensions'
import withAppconfig from '../AppContext'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

function RegScreen({ auth, profile, dispatch, appconfig }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [screen, setScreen] = useState('signup')
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [phone, setPhone] = useState()
  const [tcmodal, setTCModal] = useState(false)
  const [streetaddress, setStreetaddress] = useState()
  const [city, setCity] = useState()
  const [zip, setZip] = useState()
  // const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [regerror, setRegError] = useState(false)
  const [regerrormessage, setRegErrorMessage] = useState('')
  const [registerationProcess, setRegisterationProcess] = useState(false)
  // const [loginErrorMessage, setLoginErrorMessage] = useState(null)
  const tcToggle = () => setTCModal(!tcmodal)
  // const errorToggle = () => setErrorModalOpen(!errorModalOpen)
  let history = useHistory()
  let isLoggedIn = !isEmpty(auth)
  const { width } = useWindowDimensions()

  const customerAppTitle = width > 1000 ? '' : ''

  const resetFormData = () => {
    // setEmail(null)
    setFirstname(null)
    setLastname(null)
    setPassword(null)
    setStreetaddress(null)
    setCity(null)
    setZip(null)
    setPhone(null)
  }

  const onRegisterPress = async () => {
    // console.log('Pressed reset', this.state)
    if (email && firstname && lastname) {
      setRegisterationProcess(true)
      firebase.functions().region = 'europe-west1'
      let registration = firebase.functions().httpsCallable('users-registration')
      try {
        const _r = { email, phone, c: appconfig.accountkey, firstname, lastname, password }
        if (streetaddress) _r.streetaddress = streetaddress
        if (city) _r.city = city
        if (zip) _r.zip = zip
        let response = await registration(_r)
        // console.log(response)
        if (response.data.error) {
          setRegErrorMessage(response.data.message)
          setRegError(true)
        }
        resetFormData()
        setRegisterationProcess(false)
        return response
      } catch (error) {
        // console.log(error)
        setRegErrorMessage(error.message)
        setRegError(true)
        resetFormData()
        setRegisterationProcess(false)
        return { error: true, message: error.message }
      }
    }
  }

  if (isLoggedIn) {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <h2>Kirjaudu ulos</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <p className='text-center'>Olet kirjautuneena tunnuksella {profile.email}</p>
                </FormGroup>
                <Button
                  color='primary'
                  block
                  onClick={() => {
                    firebase.logout().then((reset) => {
                      dispatch({
                        type: 'USER_LOGOUT',
                        payload: null,
                      })
                      setScreen('login')
                    })
                  }}
                >
                  Kirjaudu ulos
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else if (screen === 'signup') {
    const isValidForm = email && firstname && lastname && phone && password && password.length > 6
    return (
      <div className='full-page-background'>
        {/* <TopNavigation /> */}
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <img src='https://www.cintoia.com/img/logo.png' alt='C' className='brandlogo' />
                    <span className='brandtext'>CINTOIA</span>
                    <h2>{t('rekisteroidy')}</h2>
                    <span className='appname'>{customerAppTitle}</span>
                  </div>
                </div>
                {/* <div>
                  <div className='text-center'>
                    <Button color='link' onClick={() => history.push(appconfig.pathPrefix + '/regcompany')}>{t('company_registration')}</Button>
                    </div>
                </div> */}
                <FormGroup className='loose-form'>
                  <Label for='firstname'>{t('first_name')} *</Label>
                  <Input
                    type='text'
                    name='firstname'
                    id='firstname'
                    placeholder={t('first_name')}
                    autoComplete={'given-name'}
                    onChange={(event) => setFirstname(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='lastname'>{t('last_name')} *</Label>
                  <Input
                    type='text'
                    name='lastname'
                    id='lastname'
                    placeholder={t('last_name')}
                    autoComplete={'family-name'}
                    onChange={(event) => setLastname(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='phone'>{t('phone')} *</Label>
                  <Input
                    type='text'
                    name='phone'
                    id='phone'
                    placeholder={t('phone')}
                    autoComplete={'tel'}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='email'>{t('email')} *</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    placeholder={t('email')}
                    autoComplete={'email'}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='streetaddress'>{t('address')}</Label>
                  <Input
                    type='text'
                    name='streetaddress'
                    id='streetaddress'
                    placeholder={t('address')}
                    onChange={(event) => setStreetaddress(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='city'>{t('city')}</Label>
                  <Input
                    type='text'
                    name='city'
                    id='city'
                    placeholder={t('city')}
                    onChange={(event) => setCity(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='zip'>{t('zip')}</Label>
                  <Input
                    type='number'
                    name='zip'
                    id='zip'
                    placeholder={t('zip')}
                    onChange={(event) => setZip(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='password'>
                    {t('password')} ({t('password_minimum')}) *
                  </Label>
                  <Input
                    type='password'
                    name='password'
                    id='password'
                    invalid={password && password.length < 7}
                    // placeholder='salasana (minimi 7 merkkiä)'
                    autoComplete={'new-password'}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormGroup>
                <div className='col-md-12 text-center mb-3'>
                  {t('cintoia_account.part1')}<br/>{t('cintoia_account.part2')}
                </div>

                <FormGroup className='loose-form'>
                  <p className='text-center'>
                    {' '}
                    <Button color='link' onClick={tcToggle}>
                      {t('register_accepttandc')}
                    </Button>
                  </p>
                </FormGroup>
                <Button
                  color={isValidForm ? 'primary' : 'secondary'}
                  block
                  disabled={!isValidForm}
                  onClick={() => onRegisterPress().then((result) => setScreen('regInfo'))}
                >
                  {isValidForm ? t('rekisteroidy') : t('fill_all_fields')}
                </Button>
                <Button
                  style={{ marginTop: 30 }}
                  color='link'
                  className='loose-form'
                  onClick={() => history.push(appconfig.pathPrefix + '/login')}
                >
                  {t('to_login')}
                </Button>
                {/* <Button color='link' className='loose-form' onClick={() => history.push(appconfig.pathPrefix + '/signin')}>
                  Salasana unohtunut
                </Button> */}
                <Row>
                  <Col>
                    {' '}
                    <Button size='small' color='link' className='text-muted' onClick={() => history.push(appconfig.pathPrefix + '/')}>
                      <ChevronLeftIcon /> {t('back_to_reservation')}
                    </Button>
                  </Col>
                  <Col style={{ textAlign: 'right', marginRight: 20 }}>
                    <a href="https://www.cintoia.com/privacy.html" alt="Tietosuojaseloste" target='_blank' rel="noreferrer">Tietosuojaseloste</a>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={tcmodal} toggle={tcToggle}>
          <ModalHeader toggle={tcToggle}>{t('tandc.usage_terms')}</ModalHeader>
          <ModalBody>
            <div>
              <strong>{t('tandc.title1')}</strong>
              <p>{t('tandc.body1')}</p>
              <strong>{t('tandc.title2')}</strong>
              <p>{t('tandc.body2')}</p>
              <strong>{t('tandc.title3')}</strong>
              <p>{t('tandc.body3')}</p>
              <p>{t('tandc.body4').replaceAll('{CANCELLIMIT}', appconfig.cancellimit || '24')}</p>{' '}
              <p><a href="https://www.cintoia.com/privacy.html" alt="Tietosuojaseloste" target='_blank' rel="noreferrer">Tietosuojaseloste</a></p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={tcToggle}>
              Sulje
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size='sm'
          centered
          isOpen={registerationProcess}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' />
          </ModalBody>
        </Modal>
      </div>
    )
  } else if (screen === 'regInfo') {
    if (regerror) {
      return (
        <div className='full-page-background'>
          <TopNavigation />
          <Container className='login-dialog'>
            <Row>
              <Col md='5' className='mx-auto'>
                <Form className='myform'>
                  <div className='logo mb-3'>
                    <div className='col-md-12 text-center'>
                      <h2>Rekisteröinti</h2>
                    </div>
                  </div>
                  <FormGroup className='loose-form'>
                    <p className='text-center'>{regerrormessage}</p>
                  </FormGroup>
                  <Button
                    style={{ marginTop: 30 }}
                    color='link'
                    className='loose-form'
                    onClick={() => history.push(appconfig.pathPrefix + '/login')}
                  >
                    Salasanan resetointi
                  </Button>
                  <Button
                    style={{ marginTop: 30 }}
                    color='link'
                    className='loose-form'
                    onClick={() => history.push(appconfig.pathPrefix + '/login')}
                  >
                    Kirjaudu
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      return (
        <div className='full-page-background'>
          <TopNavigation />
          <Container className='login-dialog'>
            <Row>
              <Col md='5' className='mx-auto'>
                <Form className='myform'>
                  <div className='logo mb-3'>
                    <div className='col-md-12 text-center'>
                      <h2>Rekisteröinti onnistui</h2>
                    </div>
                  </div>
                  <FormGroup className='loose-form'>
                    <p className='text-center'>Voit kirjautua sisään tunnuksella {email} alla olevasta linkistä.</p>
                  </FormGroup>
                  <Button
                    style={{ marginTop: 30 }}
                    color='link'
                    className='loose-form'
                    onClick={() => history.push(appconfig.pathPrefix + '/login')}
                  >
                    Kirjaudu
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  } else {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <h2>Oops !</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <p className='text-center'>Jouduit jonnekkin outoon paikkaan!</p>
                </FormGroup>
                <Button color='link' className='loose-form' onClick={() => history.push(appconfig.pathPrefix + '/login')}>
                  Takaisin kirjautumiseen
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withAppconfig(enhance(RegScreen))
