// import evslogo from './assets/logo_evs.png'
// import nologo from './assets/nologo.png'

export const firebaseConfig = {
  apiKey: 'AIzaSyB_aqXQI6DuVinrjeKX7doEHWEd-dIN1mQ',
  authDomain: 'falcon-328a1.firebaseapp.com',
  databaseURL: 'https://falcon-328a1.firebaseio.com',
  projectId: 'falcon-328a1',
  storageBucket: 'falcon-328a1.appspot.com',
  messagingSenderId: '640167253821',
  appId: '1:640167253821:web:8bd0d11aa501d630a59754',
  measurementId: 'G-5YLCC5G2WK',
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
  // profileFactory: (userData, profileData, firebase) => { // how profiles are stored in database
  //   const { user } = userData
  //   return {
  //     email: user.email,
  //     firstname: user.firstname,
  //     lastname: user.lastname,
  //     created: user.created
  //   }
  // }
  // fileMetadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
  //   // upload response from Firebase's storage upload
  //   const {
  //     metadata: { name, fullPath }
  //   } = uploadRes
  //   // default factory includes name, fullPath, downloadURL
  //   return {
  //     name,
  //     fullPath,
  //     downloadURL
  //   }
}

const keyMap = {
  // 'localhost': {
  //   accountkey: 'tali-2GjtFLost8pDf0dWwmNc',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: (
  //     <>
  //       <img
  //         height='40px'
  //         src='https://talitaivallahti.fi/wp-content/themes/talitaivis/images/logo-talin-tenniskeskus.svg'
  //         alt='Tali'
  //       />
  //       <img
  //         style={{ marginLeft: 20 }}
  //         height='40px'
  //         src='https://talitaivallahti.fi/wp-content/themes/talitaivis/images/logo-taivallahden-tenniskeskus.svg'
  //         alt='Taivallahti'
  //       />
  //     </>
  //   ),
  //   apptitle: {
  //     short: 'Tali ja Taivallahti',
  //     long: 'Tali ja Taivallahti varaukset'
  //   },
  //   title: {
  //     short: '',
  //     long: ''
  //   },
  //   categories: [
  //     { category: 'tennis', label: 'Tali sisäkentät' },
  //     { category: 'tennis taivallahti', label: 'Taivallahti tennis' },
  //     { category: 'ulkokentät', label: 'Tali ulkokentät' },
  //     { category: 'sulkapallo', label: 'Tali sulkapallo' },
  //   ],
  //   balances: [
  //     { venue: 'tali', category: 'cash', label: 'Kassa Tali' },
  //     { venue: 'taivallahti', category: 'cash', label: 'Kassa Taivallahti' },
  //   ],
  //   cashierpayment: false
  // },
  // 'evs.feel.cintoia.com': {
  //   accountkey: 'evs-4FD8m7rCa7wOuCADvzZZ',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: <img src={evslogo} alt='EVS' height='64px' />,
  //   title: { short: 'EVS', long: 'EVS - Aktia-halli ja Martinmäen tennishalli' },
  //   categories: [],
  //   balances: [
  //     { venue: 'aktia', category: 'cash', label: 'Kassa Aktiahalli' },
  //     { venue: 'martinmäki', category: 'cash', label: 'Kassa Martinmäki' },
  //     // { venue: 'aktia', category: 'sportmoney', label: 'Liikuntaraha Aktiahalli' },
  //     // { venue: 'martinmaki', category: 'sportmoney', label: 'Liikuntaraha Martinmäki' },
  //   ],
  //   cashierpayment: true
  // },
  // 'pickala-cintoia--staging-55xf3bqc.web.app': {
  //   accountkey: 'pickala-sdofiuawle33i5455',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: <img src='https://firebasestorage.googleapis.com/v0/b/falcon-328a1.appspot.com/o/pickala-sdofiuawle33i5455%2Fpickala-tc.png?alt=media&token=a4eb5f2e-3c65-4d2e-a82f-7727b62205bd' alt='Pickala' height='40px' />,
  //   title: { short: 'Pickala', long: 'Pickala Tennis & Padel Club' },
  //   categories: [],
  //   balances: [
  //     { venue: 'padel', category: 'cash', label: 'Kassa Padel' },
  //     { venue: 'tennis', category: 'cash', label: 'Kassa Tennis' },
  //   ],
  //   cashierpayment: true
  // },
  // 'evs-cintoia--staging-epj3tfu9.web.app': {
  //   accountkey: 'evs-4FD8m7rCa7wOuCADvzZZ',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: <img src={evslogo} alt='EVS' height='64px' />,
  //   title: { short: 'EVS', long: 'EVS - Aktia-halli ja Martinmäen tennishalli' },
  //   categories: [],
  //   balances: [
  //     { venue: 'aktia', category: 'cash', label: 'Kassa Aktiahalli' },
  //     { venue: 'martinmäki', category: 'cash', label: 'Kassa Martinmäki' },
  //     // { venue: 'aktia', category: 'sportmoney', label: 'Liikuntaraha Aktiahalli' },
  //     // { venue: 'martinmaki', category: 'sportmoney', label: 'Liikuntaraha Martinmäki' },
  //   ],
  //   cashierpayment: true
  // },
  // 'talitaivis-cintoia--staging-fcwoxr8e.web.app': {
  //   accountkey: 'tali-2GjtFLost8pDf0dWwmNc',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: (
  //     <>
  //       <img
  //         height='40px'
  //         src='https://talitaivallahti.fi/wp-content/themes/talitaivis/images/logo-talin-tenniskeskus.svg'
  //         alt='Tali'
  //       />
  //       <img
  //         style={{ marginLeft: 20 }}
  //         height='40px'
  //         src='https://talitaivallahti.fi/wp-content/themes/talitaivis/images/logo-taivallahden-tenniskeskus.svg'
  //         alt='Taivallahti'
  //       />
  //     </>
  //   ),
  //   title: {
  //     short: '',
  //     long: ''
  //   },
  //   categories: [
  //     { category: 'tennis', label: 'Tali sisäkentät' },
  //     { category: 'tennis taivallahti', label: 'Taivallahti tennis' },
  //     { category: 'ulkokentät', label: 'Tali ulkokentät' },
  //     { category: 'sulkapallo', label: 'Tali sulkapallo' },
  //   ],
  //   balances: [
  //     { venue: 'tali', category: 'cash', label: 'Kassa Tali' },
  //     { venue: 'taivallahti', category: 'cash', label: 'Kassa Taivallahti' },
  //   ],
  //   cashierpayment: false
  // },
  // 'talitaivallahti.feel.cintoia.com': {
  //   accountkey: 'tali-2GjtFLost8pDf0dWwmNc',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: (
  //     <>
  //       <img
  //         height='40px'
  //         src='https://talitaivallahti.fi/wp-content/themes/talitaivis/images/logo-talin-tenniskeskus.svg'
  //         alt='Tali'
  //       />
  //       <img
  //         style={{ marginLeft: 20 }}
  //         height='40px'
  //         src='https://talitaivallahti.fi/wp-content/themes/talitaivis/images/logo-taivallahden-tenniskeskus.svg'
  //         alt='Taivallahti'
  //       />
  //     </>
  //   ),
  //   title: {
  //     short: '',
  //     long: ''
  //   },
  //   categories: [
  //     { category: 'tennis', label: 'Tali sisäkentät' },
  //     { category: 'tennis taivallahti', label: 'Taivallahti tennis' },
  //     { category: 'ulkokentät', label: 'Tali ulkokentät' },
  //     { category: 'sulkapallo', label: 'Tali sulkapallo' },
  //   ],
  //   balances: [
  //     { venue: 'tali', category: 'cash', label: 'Kassa Tali' },
  //     { venue: 'taivallahti', category: 'cash', label: 'Kassa Taivallahti' },
  //   ],
  //   cashierpayment: false
  // },
  // 'talipadel.cintoia.com': {
  //   accountkey: 'talipadel-424983378',
  //   balancekeys: '',
  //   anonsignup: false,
  //   logo: <img src={nologo} alt='Talipadel' height='64px' />,
  //   title: { short: 'Talipadel', long: 'Talipadel' },
  //   categories: [],
  //   balances: [
  //     { venue: 'talipadel', category: 'cash', label: 'Talipadel' },
  //   ],
  //   cashierpayment: false
  // },

}


export const falconInstance = keyMap[document.location.hostname]

/*{
  accountkey: 'tali-2GjtFLost8pDf0dWwmNc',
  anonsignup: false
}
*/

// eslint-disable-next-line import/no-anonymous-default-export
export default { firebaseConfig, rrfConfig, falconInstance }
