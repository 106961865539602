import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom' // if you use react-router
import TopNavigation from '../TopNavigation'
import { useFirebase, firebaseConnect } from 'react-redux-firebase'
import { useCookies } from 'react-cookie'
import { compose } from 'redux'
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  // Form,
  // FormGroup,
  // Label,
  // Input,
  // Card,
  // CardBody,
  // CardSubtitle,
  // CardText,
  Modal,
  ModalBody,
  // ModalFooter,
  // CardTitle,
  // ButtonGroup,
  // ModalHeader,
  // Alert,
  Spinner,
} from 'reactstrap'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ActivityCard from '../components/ActivityCard'
// import ActivityItem from '../components/ActivityItem'
// import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
// import ViewListIcon from '@material-ui/icons/ViewList'
// import AppsIcon from '@material-ui/icons/Apps'
import useWindowDimensions from '../useWindowsDimensions'
import withAppconfig from '../AppContext'
import { useTranslation } from 'react-i18next'
import ShareDialog from '../components/ShareDialog'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import SchoolSchedule from '../components/SchoolSchedule'
import { Select } from '@material-ui/core'


// const ACTIVITIES = [
//   {
//     id: '',
//     isexternal: false,
//     externalurl: '',
//     type: 'Valmennus',
//     sport: 'padel',
//     gender: '',
//     price: 10,
//     minp: 2,
//     maxp: 8,
//     public: true,
//     participants: [],
//     start: '2022-09-30T13:30',
//     end: '2022-09-30T15:30',
//     reservations: [],
//     ingress: {
//       fi: 'Lataa akkusi täyteen virtaa maanantaisin ja perjantaisin PadelPark Raision PuuroPadelin pyörteissä. 🥣🎾',
//     },
//     description: {
//       fi: 'Uusi tapahtumamme järjestetään viikon alussa ja lopussa upeilla panoramakentillämme klo 7.00–8.30. Ensin pelataan ja sitten pelien jälkeen nautitaan vastakeitettyä puuroa, tuoreita hedelmiä sekä kahvia, teetä ja mehua. Hinta 16 €/hlö. Ovikoodi 3301. Treenin jälkeen olo on mitä parhain lähteä kohti päivän haasteita, nähdään Raisiossa! 🔥👌🏻 Voit varata kentän myös 4 henkilön porukallesi tai yhden paikan itsellesi aloittelija-, harrastaja- tai aktiiviryhmistä.',
//     },
//     hasqueue: true,
//     queue: [],
//     billable: false,
//     includedinmemberships: [],
//     options:[{ name: '', price: 8 }, { name: '', price: 40 }],
//     name: { fi: 'PuuroPadel Aloittelijoille' },
//     entry: { start: '', end: '', cancellation: '' },
//     // logo: 'https://hvstennis.fi/hvs2017/wp-content/uploads/2020/05/hvs100-logo-final-rgb.png',
//     poster:
//       'https://images.unsplash.com/photo-1601646761285-65bfa67cd7a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80',
//   },
// ]

const notHappen = (x, gtime) => {
  const entryPast = moment.utc(x.entryend).isBefore(gtime)
  const noParticipants = x.participants && x.participants.length === 0
  // const notEnoughtParticipants = x.participants && x.participants.length < x.minp
  const missingParticipantsData = !x.participants
  return entryPast && (noParticipants || missingParticipantsData)
}

const mapStateToProps = (state, props) => {
  const {
    globaltime: { gtime },
  } = state
  if (state.firebase.data.activities && state.firebase.data.activities[props.appconfig.accountkey]) {
    const _activities = JSON.parse(JSON.stringify(state.firebase.data.activities[props.appconfig.accountkey]))
    const activities = []
    const types = [] //[{ value: 'all', label: 'Kaikki' }]
    const sports = []
    for (let a in _activities) {
      activities.push({ id: a, ..._activities[a] })
      const isAlreadyIn = types.find((t) => t.value === _activities[a].type.toLowerCase())
      const isAlreadySport = sports.find((t) => _activities[a].sport && t.value === _activities[a].sport.toLowerCase())
      if (!isAlreadyIn) {
        types.push({ value: _activities[a].type.toLowerCase(), label: _activities[a].type })
      }
      if (!isAlreadySport) {
        if (_activities[a].sport) {
          const capFirst = _activities[a].sport.toLowerCase().charAt(0).toUpperCase() + _activities[a].sport.toLowerCase().slice(1)
          sports.push({ value: _activities[a].sport.toLowerCase(), label: capFirst })
        }
      }
    }
    if (types.length > 1) {
      types.unshift({ value: 'all', label: 'Kaikki', t: 'all' })
    }
    if (sports.length > 1) {
      sports.unshift({ value: 'all', label: 'Kaikki', t: 'all' })
    }

    const __activities = activities
      .filter((x) => x.state === 'published')
      .filter((x) => moment.utc(x.end).isAfter(gtime))
      .filter((x) =>  !notHappen(x, gtime)) 
      .sort((a, b) => {
        if (a.start < b.start) {
          return -1
        }
        if (a.start > b.start) {
          return 1
        }
        return 0
      }).sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1
        }
        if (!a.pinned && b.pinned) {
          return 1
        }
        return 0
      })
      console.log('activities', __activities)

    return {
      activities: __activities,
      types,
      sports,
      gtime
    }
  } else {
    return {
      activities: [],
      types: [],
      sports: [],
      gtime
    }
  }
}

const withActivities = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => [
    {
      path: 'activities/' + props.appconfig.accountkey,
      queryParams: ['orderByChild=displayName'],
    },
  ]),
  connect(mapStateToProps)
)

function FilterGroup({ options, value, onChange, type, selectionLabel, classNames = [], style = {} }) {
  const { t } = useTranslation()

  if (options.length < 2) return null
  if (type === 'buttons') {
    return (
      <ButtonGroup size={'sm'} className='flex-wrap' style={style}>
        {options.map((option) => {
          return (
            <Button
              size={'sm'}
              key={option.value}
              color='primary'
              onClick={() => onChange({ target: { value: option.value } })}
              active={value === option.value}
              outline
            >
              {option.t ? t([option.t, option.label]) : option.label}
            </Button>
          )
        })}
      </ButtonGroup>
    )
  } else if (type === 'dropdown') {
    return (
      <FormControl className={classNames.join(' ')} style={style}>
        <InputLabel id={(selectionLabel || 'dropdown') + '-select-label'}>{t(selectionLabel)}</InputLabel>
        <Select
          labelId={(selectionLabel || 'dropdown') + '-select-label'}
          id={(selectionLabel || 'dropdown') + '-select'}
          value={value}
          onChange={onChange}
          style={{ width: '100%' }}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.t ? t([option.t, option.label]) : option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

const viewTypes = [{
  value: 'listview',
  label: 'Listanäkymä',
  t: 'listview'
},
  {
    value: 'schoolschedule',
    label: 'Viikko-ohjelma',
    t: 'schoolschedule'
  }]

function ActivitiesScreen({ auth, activities, dispatch, appconfig, types, gtime, sports }) {
  const params = useParams()
  const firebase = useFirebase()
  // const [types, setTypes] = useState([
  //   { value: 'all', label: 'Kaikki' },
  //   { value: 'valmennus', label: 'Valmennus' },
  // ])
  const [pages, setPages] = useState(1)
  const [selectedactivity, setSelectedActivity] = useState(null)
  const [type, setType] = useState(types && types.length > 0 ? types[0].value : 'all')
  const [sport, setSport] = useState(sports && sports.length > 0 ? sports[0].value : 'all')
  const [view, setView] = useState('grid')
  const [viewType, setViewType] = useState('listview') // schoolschedule
  const [sharelink, setShareLink] = useState('')
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [socialOpen, setSocialOpen] = useState(false)
  let defaultLanguage = 'FI'
  const { width } = useWindowDimensions()
  const history = useHistory()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  
  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])
  
  let aid = params.aid
  if (aid === 'all') aid = type

  useEffect(() => {
    if (types && types.length > 0 && type === 'all') {
      setType(types[0].value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types])

  useEffect(() => {
    if (sports && sports.length > 0 && sport === 'all') {
      setSport(sports[0].value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [sports])

  const handleViewTypeChange = (event) => {
    setViewType(event.target.value)
    setPages(1)
  }

  const handleTypeChange = (event) => {
    setType(event.target.value)
    setPages(1)
  }

  const handleSportChange = (event) => {
    setSport(event.target.value)
    setPages(1)
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const goToPayActivity = (activity) => {
    if (activity.isexternal) {
      openInNewTab(activity.externalurl)
    } else {
      dispatch({
        type: 'UPDATE_PAYABLE_ACTIVITY',
        payload: activity,
      })
      history.push(appconfig.pathPrefix + '/bookactivity')
    }
  }

  const closeSocial = () => {
    setSocialOpen(false)
  }

  const cancelModal = () => {
    setSelectedActivity(null)
    setConfirmationOpen(false)
  }

  const shareClick = (activity) => {
    const link =
      'https://' +
      (appconfig.istest ? 'utest.cintoia.com' + appconfig.pathPrefix : appconfig.hosts[0]) +
      '/activities/' +
      activity.id
    setShareLink(link)
    setSocialOpen(true)
  }

  const confirmCancel = (activity) => {
    setSelectedActivity(activity)
    setConfirmationOpen(true)
  }

  const cancelActivity = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    const activityObject = {
      id: selectedactivity.id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testactivities-cancel' : 'activities-cancel'
    let cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(activityObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  if (width < 770 && view === 'horizontal') setView('grid')


  const filtered = activities.filter((x) => params.aid === 'all' || params.aid === x.id)
    .filter((x) => !type || (type && (type === x.type.toLowerCase() || type === 'all')))
    .filter((x) => !sport || (sport && (sport === x.sport.toLowerCase() || sport === 'all')))

  
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        <Row>
          <Col className='text-left mb-2'>
            <FilterGroup options={types} value={type} onChange={handleTypeChange} type={'dropdown'} selectionLabel={'Tapahtuma'} />
            <FilterGroup options={sports} value={sport} onChange={handleSportChange} type={'dropdown'} selectionLabel={'Laji'} style={{ marginLeft: 20 }}/>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10, alignItems: 'flex-end' }}>
          <Col xs={12} md={9}>
            <FilterGroup options={viewTypes} value={viewType} onChange={handleViewTypeChange} type={'buttons'} selectionLabel={'viewtype'} />
          </Col>

          {/* {width >= 770 ? (
            <Col className='text-right'>
              {t('views')} <AppsIcon color={view === 'grid' ? '' : 'action'} onClick={() => setView('grid')} />{' '}
              <ViewListIcon color={view === 'horizontal' ? '' : 'action'} onClick={() => setView('horizontal')} />{' '}
            </Col>
          ) : null} */}
        </Row>
        {viewType === viewTypes[0].value ? (
          <>
        <Row>
          {filtered.splice(0, pages * 12)
            .map((a) => (
              <ActivityCard
                activity={a}
                horizontal={view}
                paymentAction={goToPayActivity}
                cancelAction={confirmCancel}
                uid={auth.uid}
                shareClick={shareClick}
                gtime={gtime}
              />
            ))}
          </Row>
                      {filtered.length > pages * 9 ? (
                        <Row>
                        <Col className='text-center'>
                        <Button color='primary' onClick={() => setPages(pages + 1)} outline style={{ borderRadius: 18 }}>
                          {t('loadmore')}
                        </Button>
                        </Col>
                        </Row>
        ) : null}
        </>
          
        ) : (
        <SchoolSchedule activities={filtered} gtime={gtime} uid={auth.uid}/>
        )}
      </Container>
      <Modal
        size='sm'
        centered
        isOpen={loading}
        backdrop={true}
        wrapClassName={'al-wrap'}
        modalClassName={'al-modal'}
        contentClassName={'loading-spinner'}
      >
        <ModalBody className='text-center'>
          <Spinner color='light' /> <p style={{ color: 'white' }}>{t('cancelling')}</p>
        </ModalBody>
      </Modal>
      <Dialog
        open={confirmationOpen}
        onClose={() => cancelModal()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Haluatko varmasti perua osallistumisen?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelModal()} color='secondary'>
            {t('back')}
          </Button>
          <Button onClick={() => cancelActivity()} color='danger' autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <ShareDialog open={socialOpen} onClose={closeSocial} link={sharelink} />
    </div>
  )
}

export default withAppconfig(withActivities(ActivitiesScreen))
