import { Link, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Carousel from 'react-material-ui-carousel'
import moment from 'moment'
import { useHistory } from 'react-router'
import withAppconfig from '../AppContext'

const textInfoStyle = {
  // backgroundColor: '#cff4fc',
  background: 'linear-gradient(rgb(48 173 255), rgb(109, 213, 250), rgb(195 233 255))',
  borderRadius: 5,
  padding: 8,
  borderColor: '#b6effb',
  borderStyle: 'solid',
  borderWidth: 1,
}

const textAdStyle = {
  backgroundColor: '#ffffff',
  borderRadius: 0,
  padding: 8,
  borderColor: '#cccccc',
  borderStyle: 'solid',
  borderWidth: 1,
  textAlign: 'left',
}

const activityAdStyle = {
  backgroundColor: '#ffffff',
  borderRadius: 0,
  padding: 8,
  borderColor: '#cccccc',
  borderStyle: 'solid',
  borderWidth: 0,
  textAlign: 'left',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: 80,
  cursor: 'pointer'
}

function Banner(props) {
  const history = useHistory()
  const { format, content, linktitle, activities } = props.banner
  let { link, externalctt } = props.banner
  if (link && link.indexOf('https') === -1) {
    link = 'https://' + link
  }
  if (format === 'info') {
    return (
      <Typography variant='body2' style={textInfoStyle}>
        {content}
      </Typography>
    )
  } else if (format === 'textad') {
    if (link === null) return null
    const { hostname } = new URL(link)
    if (externalctt) {
      link = externalctt
    }
    return (
      <div style={textAdStyle}>
        <Typography variant='caption' style={{ color: 'green' }}>
          <div style={{ margin: 0, padding: 0, marginBottom: -10 }}>
            <Link href={link} target='_blank' rel='noopener' variant='body1' style={{ color: 'blue' }}>
              {linktitle}
            </Link>
          </div>
          {hostname}
        </Typography>
        <Typography variant='body2'>{content}</Typography>
      </div>
    )
  } else if (format === 'activitiesroll') {
    if (!activities) return null  
    return (
      <div style={{ ...activityAdStyle, backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)), url(${activities.image})` }}
        onClick={(e) => {
          history.push(props.appconfig.pathPrefix + '/activities/all')
              // handleInfoClick(e, activity)
            }}
      >
        <Typography variant='caption' style={{ color: 'green' }}>{activities.title}</Typography>
        <Typography variant='body2'>{activities.description}</Typography>
      </div>
    )
  }
}



function TextBannerCarouselPlacement({ banners, date, selectedCategory, appconfig }) {
  // const [selectedTextBanner, setSelectedTextBanner] = useState(null)
  const [currentBanners, setCurrentBanners] = useState([])
  // const [index, setIndex] = useState(0)

  useEffect(() => {
    if (!banners) return
    if (banners.length === 0) return
    let _b = banners
      .filter((b) => moment.utc(date).isBetween(moment.utc(b.start), moment.utc(b.end), undefined, '[]'))
      .map((_b) => {
        _b.hascats = false
        if (_b.categories && _b.categories.length > 0) {
          _b.cats = _b.categories
            .toLowerCase()
            .split(',')
            .map((c) => c.trim())
          _b.hascats = true
        }
        return _b
      }).filter((b) => !b.hascats || b.cats.includes(selectedCategory))
    setCurrentBanners(_b)
  }, [banners, date, selectedCategory])

  if (!currentBanners) return null
  if (currentBanners.length === 0) {
    return null
  } else {
    return (
      <Carousel
        autoPlay={true}
        indicators={currentBanners.length > 1 ? true : false}
        animation='fade'
        interval={10000}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={true}
        timeout={500}
        cycleNavigation={true}
        index={0}
      >
        {
          currentBanners.map((banner, i) => <Banner key={i} banner={banner} appconfig={appconfig} />)
        }
      </Carousel>
    )
  }



}

export default withAppconfig(TextBannerCarouselPlacement)
