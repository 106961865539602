import React, { useState } from 'react'
import { isLoaded, useFirebase, firestoreConnect } from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
// import Checkbox from '@material-ui/core/Checkbox'

import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import PaymentRedirect from './PaymentRedirect'
import PaymentOptions from './PaymentOptions'
import { useEffect } from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import withAppconfig from '../AppContext'
import Payments from '../components/Payments'
import { getWallet } from '../components/ViewUtils'
import CheckoutItem from '../components/CheckoutItem'

const getReservationSummary = (item) => {
  const displayName = item.resourcegroups[0].resources[0].displayName
  let _start = moment.utc(item.resourcegroups[0].start)
  let _end = moment.utc(item.resourcegroups[0].end)
  if (item.occurency) {
    let _date = moment.utc(item.occurency.date)
    _start.year(_date.year()).month(_date.month()).date(_date.date())
    _end.year(_date.year()).month(_date.month()).date(_date.date())
  }

  const category = item.resourcegroups[0].resources[0].category
  const duration = moment(_end).diff(moment(_start), 'minutes')
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  const durationString = `${hours}h ${minutes}min`
  let _sum = 0

  if (item.vs && item.vs.missingPayment && item.vs.missingPayment.missing) {
    _sum = item.vs.missingPayment.amount
  } else {
    let _sums = 0
    if (item.payments) {
      _sums = item.payments.reduce((a, x) => a + x.amount, 0) / 100
      if (item.occurency) {
        _sums =
          item.payments
            .filter((x) => item.occurency && x.occurency === item.occurency.date)
            .reduce((a, x) => a + x.amount, 0) / 100
      }
    }
    const cost = item.resourcegroups[0].overridecost
      ? item.resourcegroups[0].overridecost
      : item.resourcegroups[0].cost || 0
    _sum = cost - _sums
  }
  const price = _sum // cost - _sum
  const priceString = `${price} €`

  return {
    duration,
    durationString,
    priceString,
    displayName,
    start: _start,
    end: _end,
    category,
    price,
    isrecurring: item.isrecurring,
  }

}

const getActivitySummary = (item) => {
  return {
    start: moment.utc(item.start),
    end: moment.utc(item.end),
    displayName: item.name.fi,
    priceString: `${item.price} €`,
    price: item.price || 0,
    collection: 'activity',
  }
}

const enhance = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect(({ payable: { selected }, firebase: { auth, profile }, firestore }, { appconfig }) => {
    if (selected) {
      console.log('selected', selected)
      const summary = {
        price: 0,
      }
      const rows = selected
        .map((item) => {
          if (item?.resourcegroups?.[0]?.resources?.length > 0) {
            const _summary = getReservationSummary(item)
            return {
              ...item,
              summary: _summary,
            }
          } else if (item.collection === 'activities') {
            const _summary = getActivitySummary(item)
            return {
              ...item,
              summary: _summary,
            }
          }
          return null
        })
        .filter((x) => x)
      summary.price = rows.reduce((a, x) => a + x.summary.price, 0)

      let wallet = []
      const walletName = getWallet(appconfig, summary)
      if (firestore.data['wallets'] && firestore.data['wallets'][walletName]) {
        wallet = firestore.data['wallets'][walletName]
      }

      const cashsum =
        wallet && wallet.cash
          ? wallet.cash.reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
          : 0

      const sportmoneysum =
        wallet && wallet.sportmoney
          ? wallet.sportmoney.reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
          : 0
      return {
        auth,
        profile,
        rows,
        summary,
        cashsum,
        sportmoneysum,
        wallet: firestore.data['wallets'] || [],
      }
    } else {
      return {
        auth,
        profile,
        summary: {},
        cashsum: 0,
        sportmoneysum: 0,
        rows: null,
      }
    }
  })
)

const activityfns = ['testactivities-paymulti', 'activities-paymulti']
const reservationfns = ['testreservations-paymultiv4', 'reservations-paymultiv4']

const PastPaymentScreen3 = ({ auth, profile, rows, summary, cashsum, sportmoneysum, wallet, dispatch, appconfig }) => {
  const firebase = useFirebase()
  let history = useHistory()
  let defaultLanguage = 'FI'
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [renderPayments, setRenderPayments] = useState(false)
  const [token, setToken] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [payments, setPayments] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  const [initdone, setInitDone] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  const [onlinepaymentprovider, setOnlinePaymentProvider] = useState(null)
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  // eslint-disable-next-line no-unused-vars
  const [debugs, setDebugs] = useState([])

  useEffect(() => {
    moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
  }, [defaultLanguage])

  useEffect(() => {
    if (!token) {
      getToken()
    }
  })

  const unallowedmethods = ['minutestore']
  const isAllSameWallet = rows ? rows.every((val, i, arr) => val.summary.category === arr[0].summary.category) : true
  const isActivityPayment = rows ? rows.every((val, i, arr) => val.summary.collection === 'activity') : false
  const isReservationPayment = rows ? rows.every((val, i, arr) => val.summary.collection !== 'activity') : false
  const isAllActivitiesSameSport = rows && isActivityPayment ? rows.every((val, i, arr) => val.sport === arr[0].sport) : false

  console.log('isActivityPayment', isActivityPayment, 'isReservationPayment',  isReservationPayment, 'isAllSameWallet', isAllSameWallet)

  if (isActivityPayment && !isAllActivitiesSameSport) {
    unallowedmethods.push('benefitcard')
  }

  if (!isAllSameWallet) {
    unallowedmethods.push('cashwallet')
    unallowedmethods.push('sportmoneywallet')
  }
  const disallowstore = rows ? rows.find((x) => x.isrecurring ) : true
  if (disallowstore) {  
    unallowedmethods.push('cashstore')
    unallowedmethods.push('minutestore')
    unallowedmethods.push('benefitcard')
  }
  if (!appconfig.storage && !unallowedmethods.includes('cashstore')) {
    unallowedmethods.push('cashstore')
  }
  if (!appconfig.storage && !unallowedmethods.includes('minutestore')) {
    unallowedmethods.push('minutestore')
  }

  if (!appconfig.cashierpayment) 
    unallowedmethods.push('cashier')

  const loaded = isLoaded(profile) && isLoaded(wallet)

  const getToken = async () => {
    const tokenResult = await firebase.auth().currentUser.getIdTokenResult()
    setToken(tokenResult.token)
  }

  const setPaymentProvider = (p) => setOnlinePaymentProvider(p)

  const setPaymentSummary = (sportmoneysaldo, cashwallet, online) => {
    // console.log('setPaymentSummary', sportmoneysaldo, cashwallet, online)
    const checkedPayments = []
    let selectedPrice = summary.price * 100
    // const selectedPrice = selected.price * 100
    let leftToPay = selectedPrice
    if (sportmoneysum > 0) {
      let charged = 0
      if (sportmoneysaldo) {
        if (leftToPay <= sportmoneysum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = sportmoneysum
          leftToPay = leftToPay - sportmoneysum
        }
      }
      checkedPayments.push({ type: 'sportmoneysaldo', charged })
    }
    if (cashsum > 0) {
      let charged = 0
      if (cashwallet) {
        if (leftToPay <= cashsum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = cashsum
          leftToPay = leftToPay - cashsum
        }
      }
      checkedPayments.push({ type: 'cashwallet', charged })
    }
    if (leftToPay > 0 && online) {
      checkedPayments.push({ type: 'paytrail', charged: leftToPay })
    } else {
      checkedPayments.push({ type: 'paytrail', charged: 0 })
    }
    setPayments(checkedPayments)
  }

  if (loaded && !initdone) {
    setPaymentSummary(false, false, false)
    setInitDone(true)
  }

  const goBack = () => {
    dispatch({
      type: 'CLEAR_PAYABLE_RESERVATION',
      payload: null,
    })
    dispatch({
      type: 'CLEAR_PAYABLE_ACTIVITY',
      payload: null,
    })
    history.goBack()
    // history.push(appconfig.pathPrefix + '/' + from || 'reservations')
  }

  const postpayReservation = async (selectedPayments) => {
    setLoading(true)
    setDebugs(selectedPayments)
    // const _resource = { ...summary }
    // delete _resource.start
    // delete _resource.end
    // _resource.locationid = appconfig.accountkey
    const cartObject = {
      customerid: appconfig.accountkey,
      rids: rows.map((x) => ({ id: x.id, occurency: x.occurency })),
      paymentmethod: selectedPayments
    }

    // const reservationObject = {
    //   // id: selected.id,
    //   start: summary.start,
    //   duration: summary.duration,
    //   resource: _resource,
    //   paymentmethod: selectedPayments, // online [{ type }]
    // }
    // const occurency = selected?.occurency?.date
    // if (occurency) reservationObject.occurency = occurency

    const shouldRenderOptions = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
    const shouldRedict = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
    firebase.functions().region = 'europe-west1'
    const fns = isActivityPayment ? activityfns : reservationfns
    const fn = appconfig.istest ? fns[0] : fns[1]
    const pay = firebase.functions().httpsCallable(fn)
    try {
      let response = await pay(cartObject)
      // console.log(response.data)
      if (response.data.error) {
        setLoading(false)
        setErrorNotification(response.data.error)
      } else if (shouldRenderOptions) {
        setRenderPayments(true)
      } else if (shouldRedict) {
        setRedirect(true)
      } else {
        history.push(appconfig.pathPrefix + '/reservations')
      }
    } catch (error) {
      alert(error.message)
      // console.log(error)
      setLoading(false)
    }
  }

  if (!rows) {
    return <Redirect to='/' />
  }

  if (renderPayments) {
    return (
      <PaymentOptions
        selectedGw={'pc'}
        authorization={token}
        provider={onlinepaymentprovider}
        disabledmethods={appconfig.disabledpaymentmethods || []}
      />
    )
  } else if (redirect) {
    return <PaymentRedirect selectedGw={'pc'} authorization={token} />
  }

  if (!loaded) {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Spinner /> Loading ...
        </Container>
      </div>
    )
  } else {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>{t('payment')}</CardHeader>
                {rows.map((row, index) => (
                  <CheckoutItem row={row} key={row.id + '-' + index} />
                ))}
                <CardBody>
                  <Row>
                    <Col className={'text-right'}>{t('total')} {summary.price} €</Col>
                  </Row>
                </CardBody>
                <CardBody>
                  <Payments
                    price={summary.price}
                    selecttype='payable'
                    unallowedmethods={unallowedmethods}
                    buttonText={t('pay')}
                    buttonAction={postpayReservation}
                    errorNotification={errorNotification}
                    setPaymentProvider={setPaymentProvider}
                    ready={true}
                  />
                  <Button size='small' color='link' className='text-muted' onClick={() => goBack()}>
                    <ChevronLeftIcon /> {t('back')}
                  </Button>
                  {/* <pre>aktiviteetteja kaikki {isActivityPayment ? 'true' : 'false'}</pre>
                  <pre>{JSON.stringify(rows, null, 2)}</pre> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size='sm'
          centered
          isOpen={loading}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' /> <p style={{ color: 'white' }}>Vahvistetaan</p>
          </ModalBody>
        </Modal>
        <pre>
          {JSON.stringify(unallowedmethods, null, 2)}
        </pre>
      </div>
    )
  }
}

export default withAppconfig(enhance(PastPaymentScreen3))
