import moment from 'moment'

const getUnixTime = (reservation, border) => {
  const timeOfDayHelper = moment.utc(reservation.resourcegroups[0][border])
  const ordering = moment.utc(reservation.occurency.date)
  ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
  if (moment().utcOffset() > 0) {
    ordering.subtract(moment().utcOffset(), 'minute')
  } else {
    ordering.add(moment().utcOffset(), 'minute')
  }
  return ordering.unix()
}

export const hasResource = (r) => {
  return (
    r.resourcegroups && r.resourcegroups[0] && r.resourcegroups[0].resources && r.resourcegroups[0].resources.length > 0
  )
}

const isInvoiced = (reservation) => {
  const { occurency, payments } = reservation
  if (!payments) return false
  if (occurency) {
    return payments.filter((x) => x.occurency === occurency.date).filter((x) => x.type === 'invoice').length > 0
  } else {
    return payments.filter((x) => x.type === 'invoice').length > 0
  }
}

const getDurationAsMinutes = (reservation) => {
  const { resourcegroups } = reservation
  if (resourcegroups && resourcegroups.length > 0) {
    const { start, end } = resourcegroups[0]
      const _start = moment.utc(start).year(2020).month(1).date(1)
    const _end = moment.utc(end).year(2020).month(1).date(1)
    return _end.diff(_start, 'minutes')
  } else {
    return 0
  }
}

const hasCashPayments = (reservation) => {
  const { payments, occurency, isrecurring } = reservation
  if (!payments) return false
  if (occurency) {
    if (!isrecurring) {
      return payments.filter((x) => x.type === 'cash' || x.type === 'online').length > 0
    } else {
      return payments.filter((x) => x.occurency === occurency.date).filter((x) => x.type === 'cash' || x.type === 'online').length > 0
    }
  } else {
    return payments.filter((x) => x.type === 'cash' || x.type === 'online').length > 0
  }
}

const missingPayment = (reservation, costAsCents) => {
  const { payments, occurency, salestatus, isrecurring } = reservation
  const duration = getDurationAsMinutes(reservation)
  const _costAsCents = costAsCents

  if (occurency && occurency.salestatus) {
    const salestatus = occurency.salestatus
    // console.log('occurency', occurency)
    // console.log('salestatus', salestatus)
    costAsCents = Math.round(_costAsCents * (1 - salestatus.p))
    // console.log('costAsCents', costAsCents)
  } else if (salestatus) {
    // console.log('salestatus', salestatus)
    costAsCents = Math.round(_costAsCents * (1 - salestatus.p))
    // console.log('costAsCents', costAsCents)
  } else if (occurency && occurency.refundinvoicing) {
    costAsCents = Math.round(_costAsCents * (reservation.occurency.refundinvoicing / 100))
  } 


  if (!payments)
    return { missing: costAsCents > 0, amount: parseFloat((costAsCents / 100).toFixed(2)), haspayments: false }

  if (occurency) {
    const occurencyPayments = payments.filter((x) => {
      if (isrecurring) {
        if (x.occurency) {
          return x.occurency === occurency.date
        } else {
          return true
        }
      } else {
        return true
      }
    })
    const paidAsCents = occurencyPayments
      .filter((x) => x.type !== 'refund' && x.type !== 'minutestore')
      .reduce((a, x) => a + x.amount, 0)

    const paidAsMinutes = occurencyPayments.filter((x) => x.type === 'minutestore').reduce((a, x) => a + x.amount, 0)

    if (paidAsMinutes === duration) {
      return { missing: false, amount: 0, haspayments: true }
    }

    const amount = parseFloat(((costAsCents - paidAsCents) / 100).toFixed(2))
    return { missing: paidAsCents < costAsCents, amount, haspayments: true }
  } else {
    const paidAsCents = payments.filter((x) => x.type !== 'refund' && x.type !== 'minutestore').reduce((a, x) => a + x.amount, 0)
    const paidAsMinutes = payments.filter((x) => x.type === 'minutestore').reduce((a, x) => a + x.amount, 0)
    if (paidAsMinutes === duration) {
      return { missing: false, amount: 0, haspayments: true }
    }
    const amount = parseFloat(((costAsCents - paidAsCents) / 100).toFixed(2))
    return { missing: paidAsCents < costAsCents, amount, haspayments: true }
  }
}

const refundedPayment = (reservation) => {
  const { payments, occurency } = reservation
  if (!payments) return { refunded: false, amount: 0 }
  let timestamp = ''
  if (occurency) {
    let _hasRefundPayment = false
    let _isMinuteRefund = false
    const _amount = payments
      .filter((x) => x.type === 'refund')
      .filter((x) => x.occurency === occurency.date)
      .reduce((a, x) => {
        if (x.refundtype === 'minutestore') _isMinuteRefund = true
        _hasRefundPayment = true
        timestamp = x.timestamp
        return a + x.amount
      }, 0)
    const amount = Math.abs(parseFloat((_amount / 100).toFixed(2)))
    return { refunded: amount > 0 || _hasRefundPayment, amount, timestamp, isMinuteRefund: _isMinuteRefund }
  } else {
    let _hasRefundPayment = false
    let _isMinuteRefund = false
    const _amount = payments
      .filter((x) => x.type === 'refund')
      .reduce((a, x) => {
        if (x.refundtype === 'minutestore') _isMinuteRefund = true
        _hasRefundPayment = true
        timestamp = x.timestamp
        return a + x.amount
      }, 0)
    const amount = Math.abs(parseFloat((_amount / 100).toFixed(2)))
    return { refunded: amount > 0 || _hasRefundPayment, amount, timestamp, isMinuteRefund: _isMinuteRefund }
  }
}

const soldPercentage = (reservation) => {
  const { occurency } = reservation
  if (occurency && occurency.refundinvoicing) {
    return occurency.refundinvoicing
  }
  return 0
}

const isRecurring = (reservation) => {
  return reservation.isrecurring || reservation.parentid
}

const isCancelled = (reservation) => {
  if (reservation?.occurency?.state) {
    return reservation?.occurency?.state === 'cancelled'
  }
  return reservation.state === 'cancelled'
}

const isOnSale = (reservation) => {
  if (reservation?.occurency?.state) {
    return reservation?.occurency?.state === 'onsale'
  }
  return reservation.state === 'onsale'
}

const isPast = (reservation) => {
  const debugTime = new URLSearchParams(window.location.search).get('time')
  if (debugTime) return reservation.ordering < moment(debugTime).unix()
  return reservation.ordering < moment().unix()
}

const isUpcoming = (reservation) => {
  // const debugTime = new URLSearchParams(window.location.search).get('time')
  // if (debugTime) {
  //   if (reservation.occurency.date.startsWith('2022-10-04')) {
  //     console.log(
  //       'isUpcoming',
  //       reservation.ordering,
  //       moment(debugTime).unix(),
  //       reservation.ordering > moment(debugTime).unix()
  //     )
  //   }
  //   return reservation.ordering > moment(debugTime).unix()
  // }
  // if (reservation.occurency.date.startsWith('2022-10-04')) {
  //   console.log('isUpcoming', reservation.ordering, moment().unix(), reservation.ordering > moment().unix())
  // }
  return reservation.ordering > moment().unix()
}

const isStarted = (reservation) => {
  const rUnix = getUnixTime(reservation, 'start')
  return rUnix > moment().unix()
}

const isStored = (reservation) => {
  return reservation.isstored || reservation?.occurency?.isstored
}

const isMoved = (reservation) => {
  if (reservation.parentid) {
    return true
  }
  return false
}

const getCost = (reservation) => {
  const { resourcegroups } = reservation
  // if (!resourcegroups) console.log(reservation)
  let cost = 0
  if (resourcegroups[0]) {
    if (resourcegroups[0].hasOwnProperty('cost')) {
      cost = parseFloat(resourcegroups[0].cost)
    }
    if (resourcegroups[0].hasOwnProperty('overridecost')) {
      if (typeof resourcegroups[0].overridecost === 'string') {
        if (!isNaN(parseFloat(resourcegroups[0].overridecost))) {
          cost = parseFloat(resourcegroups[0].overridecost)
        }
      } else {
        cost = parseFloat(resourcegroups[0].overridecost)
      }
    }
  }
  return cost
}

const saleState = (reservation) => {
  // console.log('saleState', reservation.salestatus) 
  const { salestatus } = reservation
  if (salestatus) {
    return salestatus
  } else {
    if (reservation?.occurency?.salestatus) {
      return reservation?.occurency?.salestatus
    } 
    return { s: 'checkonline' }
  }
}

const getOrganisationName = (reservation) => {
  if (reservation.persons && reservation.persons.payers && reservation.persons.payers.length > 0 && reservation.persons.payers[0].businessId) {
    return reservation.persons.payers[0].name
  }
  return null
}

export const getStatus = (reservation) => {

  const _cost = getCost(reservation)
  const _missingPayment = missingPayment(reservation, _cost * 100)
  const _refundedPayment = refundedPayment(reservation, _cost * 100)
  const _soldPercentage = soldPercentage(reservation)
  const _payedPayment = { payed: _cost - _missingPayment.amount > _cost, amount: _cost - _missingPayment.amount }
  const _isPast = isPast(reservation)
  const _isCancelled = isCancelled(reservation)
  const _isOnSale = isOnSale(reservation)
  const _isRecurring = isRecurring(reservation)
  const _isStored = isStored(reservation)
  const _isUpcoming = isUpcoming(reservation)
  const _isInvoiced = isInvoiced(reservation)
  const _isStarted = isStarted(reservation)
  const _saleState = _isOnSale ? saleState(reservation) : null
  const _isFullySold = _soldPercentage === 100
  const _isPaid = _missingPayment.missing === false || _isFullySold
  const _hasCashPayment = hasCashPayments(reservation)
  const _isCancellable = _isStarted && !_isCancelled && !_isOnSale && !_isRecurring && !_isStored && !_isInvoiced
  const _isSellable = _isStarted && !_isCancelled && !_isOnSale && !_isStored
  const _isStorable = _isStarted && !_isCancelled && _isRecurring && !_isStored
  const _showAccessCode = _isUpcoming && !_isCancelled && !_isOnSale
  const _isSplittable = false // !_isCancelled && !_isOnSale && _isRecurring && !_isPaid
  const _isRefunded = _refundedPayment.refunded === true
  const _isPayable = !_isPaid && !_isInvoiced && !(_isCancelled && !_isStored) && !_isFullySold && !(_isOnSale && _isUpcoming) && !_isRefunded
  // const _isReceiptEnabled = !_isInvoiced && _isPaid && !_isRefunded && _soldPercentage < 1 && _cost > 0
  const _isReceiptEnabled = !_isInvoiced && _isPaid && _cost > 0 && _hasCashPayment
  const _isMoved = isMoved(reservation)
  const _organisationName = getOrganisationName(reservation)
  // if (_isRecurring && _isUpcoming && reservation.id === 'u6pV5RdGrLrZVkSFZtTE' && reservation.occurency.date === '2023-01-17') {
  //   console.log('getStatus', reservation)
  //   console.log('_isPaid', _isPaid)
  //   console.log('_isFullySold', _isFullySold)
  //   console.log('_isInvoiced', _isInvoiced)
  //   console.log('_isCancelled', _isCancelled)
  //   console.log('_isOnSale', _isOnSale)
  //   console.log('_isUpcoming', _isUpcoming)
  //   console.log('_isRefunded', _isRefunded)
  //   console.log('_isStored', _isStored)
  //   console.log('------------------------------------')
  //   console.log('_isPayable', _isPayable)
  // }

  // if (reservation.parentid) {
  //   const parent = reservations.find((x) => x.id === reservation.parentid)
  //   if (parent) {
  //     // check court
  //     const courtchanged = reservation.resourcegroups[0].resources[0].id !== parent.resourcegroups[0].resources[0].id
  //     // check time
  //     const _parentstart = moment.utc(parent.resourcegroups[0].start)
  //     const _parentend = moment.utc(parent.resourcegroups[0].end)
  //     const _start = moment.utc(reservation.resourcegroups[0].start)
  //     const _end = moment.utc(reservation.resourcegroups[0].end)
  //     const timechanged = !(
  //       _parentstart.hour() === _start.hour() &&
  //       _parentstart.minute() === _start.minute() &&
  //       _parentend.hour() === _end.hour() &&
  //       _parentend.minute() === _end.minute()
  //     )
  //   }
  // }
  return {
    cost: _cost,
    isCancellable: _isCancellable,
    isCancelled: _isCancelled,
    isInvoiced: _isInvoiced,
    isMoved: _isMoved,
    isOnSale: _isOnSale,
    isPaid: _isPaid,
    isPast: _isPast,
    isPayable: _isPayable,
    isReceiptEnabled: _isReceiptEnabled,
    isRecurring: _isRecurring,
    isRefunded: _isRefunded,
    isSellable: _isSellable,
    isSplittable: _isSplittable,
    isStarted: _isStarted,
    isStorable: _isStorable,
    isStored: _isStored,
    isUpcoming: _isUpcoming,
    isFullySold: _isFullySold,
    soldPercentage: _soldPercentage,
    missingPayment: _missingPayment,
    payedPayment: _payedPayment,
    refundedPayment: _refundedPayment,
    showAccessCode: _showAccessCode,
    saleState: _saleState,
    organisationName: _organisationName
  }
}
